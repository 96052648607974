import axios, { AxiosError, AxiosResponse } from 'axios';

import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/consts/queryKeys.ts';
import { ICategory } from '@/modules/categories/types/ICategory.ts';

export type ICategoriesRequest = { status?: boolean; storeCategory?: boolean };

export function useCategories(params: ICategoriesRequest) {
  return useQuery<AxiosResponse<ICategory[]>, AxiosError>({
    queryFn: () => axios.get(`/market-service/category/`, { params }),
    queryKey: [queryKeys.categories, params],
  });
}
